<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="3"
        xl="3"
      >
        <base-material-stats-card
          color="#00695C"
          icon="mdi-account-group"
          title="Service Providers"
          :value="serviceProviders.length.toString()"
          sub-icon="mdi-clock-outline"
          :sub-text="lastUpdatedAt | diffHumans"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="3"
        xl="3"
      >
        <base-material-stats-card
          color="#01579B"
          icon="mdi-account-check"
          title="Active Check-Ins"
          :value="activeCheckInsCount.length.toString()"
          sub-icon="mdi-clock-outline"
          :sub-text="lastUpdatedAt | diffHumans"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="3"
        xl="3"
      >
        <base-material-stats-card
          color="#E65100"
          icon="mdi-account-question"
          title="Suspicious Check-Ins"
          :value="suspiciousCheckIns.length.toString()"
          sub-icon="mdi-clock-outline"
          :sub-text="lastUpdatedAt | diffHumans"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="3"
        xl="3"
      >
        <base-material-stats-card
          color="#C62828"
          icon="mdi-account-alert"
          title="Least Active Providers"
          :value="leastActiveServiceProviders.length.toString()"
          sub-icon="mdi-check"
          sub-text="No activities for last quarter."
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import moment from 'moment';

  export default {
    name: 'DashBoardStatusCount',
    filters: {
      diffHumans (val) {
        return moment(val).fromNow();
      },
    },
    data () {
      return {};
    },
    computed: {
      lastUpdatedAt () {
        return this.$store.getters['dashboard/getLastUpdatedAt'];
      },
      serviceProviders () {
        return this.$store.getters['serviceproviders/getServiceProviders'];
      },
      suspiciousCheckIns () {
        return this.$store.getters['widgets/getSuspiciousCheckIns'];
      },
      leastActiveServiceProviders () {
        return this.$store.getters['serviceproviders/getLeastActiveServiceProviders'];
      },
      activeCheckInsCount () {
        return this.$store.getters['dashboard/getActiveCheckInsCount'];
      },
    },
    mounted () {
      this.lastUpdated();
      this.getServiceProviders();
      this.getAllSuspiciousCheckIns();
      this.getLeastActiveServiceProviders();
      this.getActiveCheckInsCount();
    },
    methods: {
      lastUpdated () {
        this.$store.dispatch('dashboard/lastUpdatedAt', {
          updatedAt: moment().toISOString(),
        });
      },
      async getServiceProviders () {
        await this.$store.dispatch('serviceproviders/fetchServiceProviders');
      },
      async getAllSuspiciousCheckIns () {
        await this.$store.dispatch('widgets/fetchSuspiciousCheckIns');
      },
      async getLeastActiveServiceProviders () {
        await this.$store.dispatch('serviceproviders/fetchLeastActiveServiceProviders');
      },
      async getActiveCheckInsCount () {
        await this.$store.dispatch('dashboard/fetchActiveCheckInsCount');
      },
    },
  };
</script>
