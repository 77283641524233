<template>
  <base-chart-card
    color="#90A4AE"
  >
    <template v-slot:heading>
      <base-pie-chart
        v-if="showChart"
        :chart-data="dataCollection"
        :options="options"
        style="max-height: 150px;"
      />
    </template>
    <template v-slot:reveal-actions>
      <v-tooltip bottom>
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            v-bind="attrs"
            color="info"
            icon
            v-on="on"
          >
            <v-icon
              color="info"
            >
              mdi-refresh
            </v-icon>
          </v-btn>
        </template>

        <span>Refresh</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            v-bind="attrs"
            light
            icon
            v-on="on"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>

        <span>Change Date</span>
      </v-tooltip>
    </template>

    <h3 class="my-card-title-style">
      Longest Job Hours
    </h3>

    <p class="my-card-subtitle-style">
      Five jobs with longest work completion hours<br>
      from {{ thisMonthFirstDay }} to {{ today }}
    </p>
    <p
      v-if="noDataExist"
      class="alert-style"
    >
      No Data to Display.
    </p>

    <template v-slot:actions>
      <v-icon
        class="mr-1"
        small
        dense
      >
        mdi-clock-outline
      </v-icon>
      <span class="my-updation-text-style">Updated {{ lastUpdatedAt | diffHumans }}</span>
    </template>
  </base-chart-card>
</template>
<script>
  import moment from 'moment';
  import { isJobDataExist } from 'src/helpers/chart-data-helper';

  export default {
    name: 'DashBoardRightChart',
    filters: {
      diffHumans (val) {
        return moment(val).fromNow();
      },
    },
    data: () => ({
      title: 'Longest Job Hours',
      thisMonthFirstDay: null,
      today: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'left',
          maxHeight: '10px',
          align: 'left',
          display: true,
          textAlign: 'left',
          text: 'Longest Job Hours',
          labels: {
            fontColor: '#263238',
          },
        },
      },
    }),
    computed: {
      chartData () {
        return this.$store.getters['dashboard/getHighestJobHours'];
      },
      dataExist () {
        return this.chartData.length > 0;
      },
      showChart () {
        return Object.keys(this.dataCollection).length > 0;
      },
      dataCollection () {
        if (this.dataExist === false) {
          return {};
        }
        return this.generateDataCollection();
      },
      noDataExist () {
        const dataExist = isJobDataExist(this.chartData);
        if (dataExist === true) {
          return false;
        }
        return true;
      },
      lastUpdatedAt () {
        return this.$store.getters['dashboard/getLastUpdatedAt'];
      },
    },
    mounted () {
      this.thisMonthFirstDay = moment().startOf('month').format('DD-MM-YYYY');
      this.today = moment().format('DD-MM-YYYY');
      this.fetchHighestJobHours();
    },
    methods: {
      fetchHighestJobHours () {
        this.$store.dispatch('dashboard/fetchHighestJobHours', {
          params: {
            from: moment().startOf('month').toISOString(),
            to: moment().toISOString(),
          },
        });
      },
      generateDataCollection () {
        const actionCount = [];
        const labels = [];
        const itemColor = ['#FB8C00', '#4CAF50', '#F44336', '#4E342E', '#00838F'];
        for (const result of this.chartData) {
          actionCount.push(result.total_hours);
          labels.push(result.job_details.job_number);
        }
        return {
          labels: labels,
          datasets: [
            {
              data: actionCount,
              backgroundColor: itemColor,
              borderColor: '#fff',
              fill: false,
              hoverOffset: 4,
            },
          ],
        };
      },
    },
  };
</script>
<style scoped>
.alert-style {
  color: #C62828;
  text-align: center;
}
.my-card-title-style {
  color: #37474F;
  margin-left: 7px;
  margin-top: 10px;
  font-size: 18px;
}
.my-card-subtitle-style {
  color: #37474F;
  margin-left: 7px;
  margin-top: 7px;
  font-size: 14px;
}
.my-updation-text-style {
  color: #37474F;
  font-size: 12px;
}
</style>
