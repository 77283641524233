<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-view-dashboard
      </v-icon> Check-In Overview
    </h2>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <dash-board-data-usage />
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <v-date-picker
            v-model="picker"
            year-icon="mdi-calendar-blank"
            prev-icon="mdi-skip-previous"
            next-icon="mdi-skip-next"
            color="#FF3700"
            header-color="#37474F"
            elevation="15"
            class="my-calendar-style"
          />
        </v-col>
      </v-row>
      <dash-board-status-counts />
      <v-row>
        <v-col
          cols="12"
          lg="4"
        >
          <monthly-work-completion />
        </v-col>
        <v-col
          cols="12"
          lg="4"
        >
          <current-check-ins />
        </v-col>
        <v-col
          cols="12"
          lg="4"
        >
          <highest-job-hrs-monthly />
        </v-col>
      </v-row>
    </v-card>
    <br>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>
<script>
import DashboardDataUsage from './dashboards/DashboardDataUsage.vue';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import DashBoardStatusCounts from './dashboards/DashboardStatusCounts.vue';
import MonthlyWorkCompletion from './dashboards/charts/MonthlyWorkCompletion.vue';
import CurrentCheckIns from './dashboards/charts/CurrentCheckIns.vue';
import HighestJobHrsMonthly from './dashboards/charts/HighestJobHrsMonthly.vue';

export default {
  name: 'Dashboard',
  components: {
    'dash-board-data-usage': DashboardDataUsage,
    'centre-spinner': spinner,
    'dash-board-status-counts': DashBoardStatusCounts,
    'monthly-work-completion': MonthlyWorkCompletion,
    'current-check-ins': CurrentCheckIns,
    'highest-job-hrs-monthly': HighestJobHrsMonthly,
  },
  data () {
    return {
      picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      loading: false,
    };
  },
  async mounted () {
    this.loading = true;
    await this.$store.dispatch('orgsettings/fetchOrgSettings');
    this.loading = false;
  },
};
</script>
<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-calendar-style {
  margin-right: 20px;
  margin-top: 10px;
  float: right;
}
.my-carousel-style {
  align-items: center;
  object-position: center;
  text-align: center;
}
</style>
