export function isWorkCompletionDataExist (chartData) {
    for (const data of chartData) {
        if (data.completed_jobs > 0) {
            return true;
        }
    }
    return false;
};
export function isCurrentCheckInsDataExist (chartData) {
    for (const data of chartData) {
        if (data.active_users.length > 0) {
            return true;
        }
    }
    return false;
};
export function isJobDataExist (chartData) {
    for (const data of chartData) {
        if (data.total_hours > 0) {
            return true;
        }
    }
    return false;
};
